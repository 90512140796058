import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { GjRoadJeepPath } from "../../assets/misc-svgs/components";
import { Text } from "../Text";

import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { pxToRem } from "../../utils/px-to-rem";
import { enterTextViaScrambling } from "../../utils/text-animations";
import { useImagesLoaded } from "../../utils/use-images-loaded";

interface IFfRoad {
    onComplete: () => void;
}

const TOTAL_DURATION = 30;
const TOTAL_GJ_COUNT = 16;

const MashhoorImage = () => (
    <div
        className="mashhoor-image"
        css={{
            position: "absolute",
            display: "grid",
            zIndex: 199
        }}
    >
        <img css={{ width: "18vw", marginTop: "-4vw", marginLeft: "4.5vw" }} src="/images/mashoor.png" />
    </div>
);

export const FfRoad = ({ onComplete }: IFfRoad) => {
    const mainRef = useRef(null);

    const mashhoorRef = useRef<HTMLImageElement>(null);

    const { imagesLoaded } = useImagesLoaded();

    let mainTl = null;

    useLayoutEffect(() => {
        gsap.registerPlugin(MotionPathPlugin);
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({ paused: true })
                .from(".gj-road-text", { autoAlpha: 0 })
                .from(".mashhoor-image", {
                    autoAlpha: 0
                })
                .add(
                    enterTextViaScrambling(
                        gsap.utils.toArray(".caption-text-1"),
                        3
                    ).play()
                )
                .add(
                    enterTextViaScrambling(
                        gsap.utils.toArray(".caption-text-2"),
                        4
                    ).play()
                );
            if (imagesLoaded) {
                const tween = gsap.to(".gulab-jamun-circle .individual-gj", {
                    paused: true,
                    // immediateRender: true,
                    duration: TOTAL_DURATION,
                    zIndex: 200,
                    motionPath: {
                        path: "svg path",
                        align: "svg path",
                        alignOrigin: [0.2, 0.5],
                        autoRotate: true
                    },
                    ease: "none",
                    stagger: {
                        each: TOTAL_DURATION / TOTAL_GJ_COUNT,
                        repeat: 0,
                        ease: "none"
                    }
                });
                tween.seek(TOTAL_DURATION);

                // console.log(mashhoorRef.current.getBoundingClientRect());
                const mashhoorBounding =
                    mashhoorRef.current.getBoundingClientRect();
                gsap.set(".mashhoor-image", {
                    x:
                        mashhoorBounding.x -
                        document.querySelector(".mashhoor-image").clientWidth /
                            3,
                    y:
                        mashhoorBounding.y +
                        document.querySelector(".mashhoor-image").clientHeight *
                            0.1
                });

                const jeepTween = gsap
                    .timeline({
                        paused: true,
                        repeat: -1
                    })
                    .set(".jeep-front", { autoAlpha: 0 })
                    .set(".jeep-side", { autoAlpha: 0 })
                    .set(".jeep-side-reverse", { autoAlpha: 0 })
                    .set(".jeep-back", { autoAlpha: 0 })
                    .to(".gulab-jamun-jeeps-container", {
                        immediateRender: true,
                        duration: TOTAL_DURATION,
                        motionPath: {
                            path: "svg path",
                            align: "svg path",
                            alignOrigin: [0.5, 0.32]
                        },
                        ease: "none"
                    })
                    .to(
                        ".gulab-jamun-circle .individual-gj",
                        {
                            visibility: "hidden",
                            ease: "none",
                            reversed: true,
                            duration: 0,
                            // duration: TOTAL_DURATION,
                            // duration: TOTAL_DURATION * 1.1,
                            stagger: {
                                amount: TOTAL_DURATION - TOTAL_DURATION * 0.06,
                                // amount: 0,
                                ease: "none"
                            }
                        },
                        TOTAL_DURATION * 0.06
                    )
                    .to(
                        ".jeep-side",
                        {
                            autoAlpha: 1,
                            duration: 0.01,
                            ease: "none"
                        },
                        "="
                    )
                    .to(
                        ".jeep-side",
                        {
                            autoAlpha: 0,
                            ease: "none",
                            duration: 0.25
                        },
                        `${TOTAL_DURATION * 0.42}`
                    )
                    .to(
                        ".jeep-front",
                        {
                            autoAlpha: 1,
                            duration: 0.25,
                            ease: "none"
                        },
                        "<"
                    )
                    .to(
                        ".jeep-front",
                        {
                            autoAlpha: 0,
                            ease: "none",
                            duration: 0.25
                        },
                        `${TOTAL_DURATION * 0.5}`
                    )
                    .to(
                        ".jeep-side-reverse",
                        {
                            autoAlpha: 1,
                            duration: 0.25,
                            ease: "none"
                        },
                        "<"
                    )
                    .to(
                        ".jeep-side-reverse",
                        {
                            autoAlpha: 0,
                            duration: 0.25,
                            ease: "none"
                        },
                        `${TOTAL_DURATION * 0.95}`
                    )
                    .to(
                        ".jeep-back",
                        {
                            autoAlpha: 1,
                            duration: 0.25,
                            ease: "none"
                        },
                        "<"
                    );

                gsap.to(".contents", { autoAlpha: 1, delay: 0 }).then(() => {
                    jeepTween.play();
                    mainTl.play().then(onComplete);
                });
            }
        }, mainRef);

        return () => ctx.revert();
    }, [imagesLoaded]);

    return (
        <div ref={mainRef}>
            <div
                className="contents"
                css={{
                    opacity: 0,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }}
            >
                <div
                    css={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        display: "grid",
                        zIndex: 201,
                        gridTemplateColumns: "3fr 4fr 4fr",
                        gridGap: pxToRem(64),
                        padding: `${pxToRem(40)} ${pxToRem(80)}`
                    }}
                >
                    <div className="gj-road-text">
                        <img
                            css={{ width: "100%" }}
                            src="/images/gj-road-text.png"
                        />
                    </div>
                    <Text
                        className="caption-text-1"
                        variant="caption"
                        color="white"
                    >
                        There is a half-kilometer stretch of highway in India
                        dedicated to the beloved gulab jamun.
                        <div css={{ marginTop: "1rem" }}>
                            This gulab jamun road is located in maigalganj,
                            150km from lucknow.
                        </div>
                    </Text>
                    <Text
                        className="caption-text-2"
                        variant="caption"
                        color="white"
                    >
                        The patch of land is home to more than 100 shops selling
                        gulab jamuns going by the same name — “Mashhoor Gulab
                        Jamun Ki Dukan”.
                    </Text>
                </div>

                <div css={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    transform: "scale(0.9)",
                    transformOrigin: "center bottom"
                }}>
                    <MashhoorImage />

                    <GjRoadJeepPath
                        id="gj-road-svg"
                        css={{
                            position: "absolute",
                            bottom: "8.2vw",
                            left: "16.5%",
                            width: "72vw",
                            transform: "rotate(180deg)",
                            opacity: 0
                        }}
                    />
                    <div
                        className="gulab-jamun-jeeps-container"
                        css={{
                            position: "absolute",
                            width: "26vw",
                            height: "26vw",
                            top: "40%",
                            ".jeep": {
                                position: "absolute",
                                left: 0,
                                top: 0,
                                right: 0,
                                zIndex: 240
                            },
                            zIndex: 240
                        }}
                    >
                        <img
                            css={{ width: "26vw", opacity: 0 }}
                            className="jeep jeep-side"
                            src="/images/jeep.png"
                        />
                        <img
                            css={{
                                width: "26vw",
                                opacity: 0,
                                marginTop: "-4%",
                                marginLeft: "16%",
                                transform: "scaleX(-1) rotate(35deg)"
                            }}
                            className="jeep jeep-side-reverse"
                            src="/images/jeep.png"
                        />
                        <img
                            css={{
                                width: "16vw",
                                opacity: 0,
                                marginTop: "12%",
                                marginLeft: "42%"
                            }}
                            className="jeep jeep-back"
                            src="/images/jeep-back.png"
                        />
                        <img
                            css={{
                                width: "17vw",
                                marginTop: "-6%",
                                marginLeft: "12%",
                                opacity: 0
                            }}
                            className="jeep jeep-front"
                            src="/images/jeep-front.png"
                        />
                    </div>

                    <div
                        css={
                            {
                                // backgroundImage: "url(/images/gj-ff-road-bg.png)",
                                // backgroundSize: "cover",
                                // backgroundPosition: "bottom",
                                // backgroundRepeat: "no-repeat",
                                // position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
                            }
                        }
                    >
                        <img
                            css={{
                                width: "100%",
                                objectFit: "cover",
                                objectPosition: "bottom",
                                position: "absolute",
                                bottom: 0
                            }}
                            src="/images/gj-ff-road-bg.png"
                        />
                    </div>

                    <div
                        className="gulab-jamun-circle"
                        css={{
                            zIndex: 200
                        }}
                    >
                        {[...Array(TOTAL_GJ_COUNT).keys()].map((_, idx) => (
                            <img
                                css={{
                                    width: "4vw",
                                    zIndex: 200,
                                    position: "relative"
                                }}
                                className="individual-gj"
                                src="/images/gulab-jamun-sphere.png"
                                {...(idx === 7 && { ref: mashhoorRef })}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
